import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views
import Home from './views/Home';
import VideoClipperPage from './views/VideoClipperPage';
import SuperSearchPage from './views/SuperSearchPage';
import EasyTranslatorPage from './views/EasyTranslatorPage';
import PrivacyPage from './views/PrivacyPage';
import TermsPage from './views/TermsPage';

class App extends React.Component {
  componentDidMount() {
    document.body.classList.add('is-loaded');
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute
              exact
              path="/video-clipper"
              component={VideoClipperPage}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/super-search"
              component={SuperSearchPage}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/easy-translator"
              component={EasyTranslatorPage}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/privacy"
              component={PrivacyPage}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/terms"
              component={TermsPage}
              layout={LayoutDefault}
            />
          </Switch>
        )}
      />
    );
  }
}

export default withRouter((props) => <App {...props} />);
